import { nanoid } from 'nanoid';
import { useState } from 'react';
import { atom, useRecoilState } from 'recoil';

export type NotificationT = {
  id?: string;
  title?: string;
  type?: 'info' | 'success' | 'warn' | 'error';
  content?: string;
  onClose?(notification: NotificationT): void;
  timeout?: number;
};

const notificationsState = atom<NotificationT[]>({
  key: 'notificationsState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export default function useNotify() {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const notify = ({
    id = nanoid(),
    title,
    type = 'info',
    content,
    onClose,
    timeout = 3000,
  }: any) => {
    setNotifications((notifications) => [
      ...notifications,
      {
        id,
        title,
        type,
        content,
        onClose,
        timeout,
      },
    ]);
  };

  const removeNotification = (id: string) => {
    setNotifications((notifications) =>
      notifications.filter((n) => n.id !== id)
    );
  };

  return { notifications, notify, removeNotification };
}
