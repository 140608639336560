import { ReactNode } from 'react';

export default function NotificationRegion({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-10"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {children}
      </div>
    </div>
  );
}
