import useNotify from '$hooks/useNotify';
import NotificationItem from './Item';
import NotificationRegion from './Region';

export default function Notification() {
  const { notifications } = useNotify();
  return (
    <NotificationRegion>
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
    </NotificationRegion>
  );
}
