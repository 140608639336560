import Notification from '$components/Notification';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import NextNProgress from 'nextjs-progressbar';
import '../styles/globals.css';

function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ [key: string]: any }>) {
  return (
    <SessionProvider session={session}>
      <RecoilRoot>
        <NextNProgress color="#048537" height={3} />
        <Component {...pageProps} />
        <Notification />
      </RecoilRoot>
    </SessionProvider>
  );
}

export default App;
