import flyFromRight from '$animations/flyFromRight';
import useNotify, { NotificationT } from '$hooks/useNotify';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

type NotificationItemProps = {
  notification: NotificationT;
};

export default function NotificationItem({
  notification,
}: NotificationItemProps) {
  const { removeNotification } = useNotify();

  useEffect(() => {
    if (notification.timeout) {
      let timeId = setTimeout(() => {
        notification.onClose && notification.onClose(notification);
        removeNotification(notification.id as string);
      }, notification.timeout);
      return () => clearTimeout(timeId);
    }
  }, [notification.timeout]);

  const onClose = () => {
    notification.onClose && notification.onClose(notification);
    removeNotification(notification.id as string);
  };

  const renderIcon = () => {
    switch (notification.type) {
      case 'success':
        return (
          <CheckCircleIcon
            className="h-6 w-6 text-green-800"
            aria-hidden="true"
          />
        );

      case 'warn':
        return (
          <ExclamationIcon
            className="h-6 w-6 text-amber-500"
            aria-hidden="true"
          />
        );

      case 'error':
        return (
          <ExclamationCircleIcon
            className="h-6 w-6 text-red-700"
            aria-hidden="true"
          />
        );

      default:
        return (
          <InformationCircleIcon
            className="h-6 w-6 text-blue-800"
            aria-hidden="true"
          />
        );
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        layoutId={notification.id}
        key={notification.id}
        className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        {...flyFromRight}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{renderIcon()}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">
                {notification.title}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {notification.content}
              </p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
